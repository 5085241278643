<template>
    <div v-if="formloading">
    <font-awesome-icon icon="spinner" class="fa-md dark-text" spin />
          <span class="dark-text">{{ $t('loading') }} </span>
    </div>
    <div id="Staff" v-else>
        <h1 class="page-title  dark-text">{{ title }}</h1>
        <div id="panel_basic_details">
        <v-card class="filter-container">
            <div>
                <v-card-title class="title"><h3 class="text-ibm-plex-sans">Basic Details</h3></v-card-title>
                <v-divider></v-divider>
                <div class="flex-container wrap">
                    <Input id="Name" class="input-container" :label="$t('Name')" :placeholderText="'Enter Name'" :isRequired="true" v-model="Name" :orientation="'v'"/>
                    <Input id="Designation" class="input-container" :label="$t('Designation')" :placeholderText="'Enter Designation'" :isRequired="true" v-model="Designation" :orientation="'v'"/>
                    <Input id="PrimaryNumber" name="PrimaryNumber" class="input-container" :addOn="this.addStaffObj.phonePrefix" :label="$t('Primary Number')" :placeholderText="'Enter Phone'" v-model="PrimaryNumber" :orientation="'v'"
                    :isRequired="true" :hasError="primaryNumberError" :errorMessage="errorMessage"/>
                    <Input id="SecondaryNumber" class="input-container" :addOn="this.addStaffObj.phonePrefix" :label="$t('Secondary Number')" :placeholderText="'Enter Secondary Phone'" :isRequired="false" v-model="SecondaryNumber" :orientation="'v'"
                    :hasError="secondaryNumberError" :errorMessage="secondaryNumberErrorMessage"/>
                    <Input id="Email" class="input-container" :label="$t('Email (Optional)')" :placeholderText="'Enter Email'" :isRequired="false" v-model="Email" :orientation="'v'"/>
                </div>
            </div>
        </v-card>
        </div>
        <div id="panel_choose_patienst">
        <v-card class="filter-container">
            <div>
                <v-card-title class="title"><h3 class="text-ibm-plex-sans">Choose Patients (to be considered for alerts)</h3></v-card-title>
                <v-divider></v-divider>
                <div class="card-part">
                    <RadioGroup :name="'choose_patients'" v-model="updatedPatientsLink" :allOptions="patientLinkRadioGroup" :label="'Select Patients'" :orientation="'v'" :required="true"></RadioGroup>
                </div>
                <div v-if="showTable" class="card-part">
                  <div v-if="addStaffObj.episodesList && addStaffObj.episodesList.length > 0">
                    <CheckboxGroup :optionsWithKeyValue="addStaffObj.episodesList"
                    name="Patients"
                    :label='""'
                    v-model="LinkedPatientIds"
                    :displayHyperlink="true"
                    :orientation="'v'"/>
                  </div>
                  <div v-else>
                    {{$t('there_are_no_patients_in_this_hierarchy')}}
                  </div>
                </div>
            </div>
        </v-card>
        </div>
        <div v-if="!isViewOnly" class="button-container">
        <Button @click="resetData" :label="$t(`cancel`)" :type="'outlined'"/>
        <Button :label="$t(`submit`)" @click="submit" :disabled="disableSubmit"/>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Input from '../../../shared/components/Input.vue'
import RadioGroup from '../../../shared/components/RadioGroup.vue'
import CheckboxGroup from '../../../shared/components/CheckboxGroup.vue'
import Button from '../../../shared/components/Button.vue'
import { defaultToast, ToastType } from '../../../../utils/toastUtils'
import { isNotNullNorEmpty } from '../../../shared/utils/Objects'

export default {
  name: 'Staff',
  data () {
    return {
      StaffId: 0,
      Name: '',
      Designation: '',
      PrimaryNumber: '',
      SecondaryNumber: '',
      Email: '',
      updatedFrequency: '',
      patientLinkRadioGroup: [
        { Key: false, Value: 'All Patients' },
        { Key: true, Value: 'Some Patients' }
      ],
      updatedPatientsLink: false,
      LinkedPatientIds: [],
      disableSubmit: false,
      title: '',
      regex: /^[0-9]{8,}$/,
      errorMessage: '',
      secondaryNumberErrorMessage: ''
    }
  },
  computed: {
    ...mapState('AddStaff', [
      'formloading',
      'previousHierarchyId',
      'addStaffObj',
      'isViewOnly'
    ]),
    showTable () {
      if (this.updatedPatientsLink) {
        return true
      } else {
        return false
      }
    },
    secondaryNumberError () {
      if (isNotNullNorEmpty(this.SecondaryNumber) && !this.regex.test(this.SecondaryNumber)) {
        this.setSecondaryErrorMessage()
        return true
      }
      this.unSetSecondaryErrorMessage()
      return false
    },
    primaryNumberError () {
      if (this.PrimaryNumber === '' || !this.regex.test(this.PrimaryNumber)) {
        this.setErrorMessage()
        return true
      }
      this.unSetErrorMessage()
      return false
    }
  },
  async mounted () {
    const pathArray = window.location.pathname.split('/')
    if (pathArray[pathArray.length - 2] === 'AddStaff') {
      this.title = 'Add Staff'
      this.setPreviousHierarchyId(parseInt(pathArray[pathArray.length - 1]))
      await this.fieldStaffDetails({ params: { hierarchyId: this.previousHierarchyId } })
    } else if (pathArray[pathArray.length - 2] === 'UpdateStaff') {
      await this.fieldStaffDetails({ params: { staffId: parseInt(pathArray[pathArray.length - 1]), hierarchyId: this.previousHierarchyId } })
      this.title = this.addStaffObj.staffResponseData.staff.staffName
    }
    await this.setAllData()
  },
  components: {
    Input,
    RadioGroup,
    Button,
    CheckboxGroup
  },
  methods: {
    ...mapActions('AddStaff', [
      'fieldStaffDetails',
      'updateFieldStaff',
      'addFieldStaff',
      'setPreviousHierarchyId'
    ]),
    async setAllData () {
      if (this.addStaffObj.staffResponseData && this.addStaffObj.staffResponseData.staff) {
        const staffData = this.addStaffObj.staffResponseData.staff
        this.StaffId = staffData.staffId
        this.Name = staffData.staffName
        this.Designation = staffData.designation
        this.PrimaryNumber = staffData.primaryNumber
        this.SecondaryNumber = staffData.secondaryNumber
        this.Email = staffData.emailAddress
        this.updatedPatientsLink = staffData.selectiveEpisodeMapping
        if (this.addStaffObj.staffResponseData.mappedEpisodes) {
          this.addStaffObj.staffResponseData.mappedEpisodes.forEach(episode => {
            this.LinkedPatientIds.push(episode.episodeId)
          })
        }
      }
      this.updatedFrequency = this.addStaffObj.AlertFrequency === '' ? 'NONE' : this.addStaffObj.AlertFrequency
      this.regex = new RegExp(this.addStaffObj.phoneValidationRegex)
    },
    validate () {
      if (!isNotNullNorEmpty(this.Name) || !isNotNullNorEmpty(this.Designation) ||
      !isNotNullNorEmpty(this.PrimaryNumber) || !this.regex.test(this.PrimaryNumber) ||
      this.secondaryNumberErrorMessage !== '') {
        return false
      }
      return true
    },
    validatePatientList () {
      if (this.updatedPatientsLink && this.LinkedPatientIds.length === 0) {
        defaultToast(ToastType.Error, 'There are no patients selected.')
        return false
      } else {
        return true
      }
    },
    setErrorMessage () {
      this.errorMessage = this.addStaffObj.phoneValidationErrorMessage
    },
    unSetErrorMessage () {
      this.errorMessage = ''
    },
    setSecondaryErrorMessage () {
      this.secondaryNumberErrorMessage = this.addStaffObj.phoneValidationErrorMessage
    },
    unSetSecondaryErrorMessage () {
      this.secondaryNumberErrorMessage = ''
    },
    async submit () {
      this.disableSubmit = true
      const isValid = this.validate()
      const validPatientList = this.validatePatientList()
      if (validPatientList) {
        if (isValid) {
          const mappedEpisodes = []
          if (this.updatedPatientsLink) {
            this.LinkedPatientIds.forEach(episodeId => {
              mappedEpisodes.push({ episodeId: episodeId })
            })
          }
          const params = {
            staffId: this.StaffId,
            firstName: this.Name,
            designation: this.Designation,
            contactNumber: this.PrimaryNumber,
            secondaryContactNumber: this.SecondaryNumber,
            emailId: this.Email,
            hierarchyId: this.previousHierarchyId,
            AlertFrequency: this.updatedFrequency,
            selectiveEpisodeMapping: this.updatedPatientsLink && this.LinkedPatientIds.length > 0,
            mappedEpisodes: mappedEpisodes
          }
          if (this.title === 'Add Staff') {
            await this.addFieldStaff({ params: params })
          } else {
            await this.updateFieldStaff({ params: params })
          }
          window.open('/dashboard/StaffDetails/' + this.previousHierarchyId, '_self')
        } else {
          defaultToast(ToastType.Error, 'Please fill all the required details.')
        }
      }
      this.disableSubmit = false
    },
    async resetData () {
      this.$router.go()
    }
  }
}
</script>

<style lang="scss" scoped>
.card-part {
  margin-top: 12px;
}
.title {
  font-size: 20px;
  flex: auto;
  padding: 10px 0px !important;
}
.filter-container{
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 15px 20px;
  box-sizing: unset!important;
  color: var(--text-primary-color);
  background-color: var(--background-color-primary-light-dark);
}
.input-container  {
  padding: 10px 8px 0px 10px;
}
.wrap {
  flex-wrap: wrap;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  grid-gap: 5px;
}
.page-title {
  margin: 20px 0px;
}
.button-container {
  justify-content: flex-end;
  display: flex;
}
.c-button {
  margin: 10px 0px 10px 10px !important;
}
</style>
